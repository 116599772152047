import { ButtonHTMLAttributes, forwardRef } from "react";
import joinClassNames from "classnames";

import classes from "./styles/classes.module.scss";

const BlankButton = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement>
>(({ children, className, ...props }, ref) => (
  <button
    ref={ref}
    className={joinClassNames(classes.blank, className)}
    {...props}
  >
    {children}
  </button>
));

export default BlankButton;
