import queryString from "query-string";

import { Pages } from "constants/pages";
import { TransactionStatuses } from "constants/categories";
import { BITCOIN_LETTER_ID } from "constants/coins";

interface BuyOptions {
  regionLetterId?: string;
  coinLetterId?: Coin["letterId"];
  networkId?: Network["id"];
  amount?: string;
}

interface BuyInsightOptions extends BuyOptions {
  insightId: string | number;
  networkId: Network["id"];
}

interface CoinsOptions {
  category?: string;
  search?: string;
  sort?: CoinsSort;
}

interface CoinOptions {
  id: string;
}

interface TransactionsOptions {
  category?: TransactionStatuses;
  transactionId?: string;
}

export const urlSelector = new (class {
  public buy({ coinLetterId, regionLetterId, ...options }: BuyOptions = {}) {
    return `${Pages.buy}/${coinLetterId || BITCOIN_LETTER_ID}${
      regionLetterId ? `/${regionLetterId}` : ""
    }?${queryString.stringify(options)}`;
  }

  public buyInsight({
    coinLetterId,
    regionLetterId,
    insightId,
    ...options
  }: BuyInsightOptions) {
    return `${Pages.buy}/${coinLetterId || BITCOIN_LETTER_ID}${
      regionLetterId ? `/${regionLetterId}` : ""
    }/${insightId}?${queryString.stringify(options)}`;
  }

  public insights() {
    return Pages.insights;
  }

  public points() {
    return Pages.points;
  }

  public coins({
    category = "all",
    search = "",
    sort = "rank",
  }: CoinsOptions = {}) {
    return `${Pages.coins}/${category}?${queryString.stringify({
      search,
      sort,
    })}`;
  }

  public coin({ id }: CoinOptions) {
    return `${Pages.coin}/${id}`;
  }

  public transactions({
    category = TransactionStatuses.all,
    transactionId,
  }: TransactionsOptions = {}) {
    return `${Pages.transactions}/${category}?${queryString.stringify({
      transactionId,
    })}`;
  }
})();
